var terminal_active = false;

document.addEventListener('keydown', function(event) {
    if (terminal_active) {
        return;
    }

    const selected = document.querySelector('.selected');
    if (event.key === 'ArrowUp') {
        event.preventDefault(); // Prevent scrolling
        if (selected.previousElementSibling) {
            selected.classList.remove('selected');
            selected.previousElementSibling.classList.add('selected');
        }
    } else if (event.key === 'ArrowDown') {
        event.preventDefault(); // Prevent scrolling
        if (selected.nextElementSibling) {
            selected.classList.remove('selected');
            selected.nextElementSibling.classList.add('selected');
        }
    } else if (event.key === 'Enter') {
        const link = selected.querySelector('a');
        if (link) {
            window.open(link.href, '_blank');
        }
    }
});



function showTerminal() {
    document.getElementById('mini-about').innerHTML = 'Minimal BASH-like line editing is supported. For the first word, TAB lists possible command completions.<br>Anywhere else TAB lists possible device or file completions. Press ESC to return to the menu.';
    document.getElementById('boot-loader').setAttribute('style', 'display: none');
    document.getElementById('terminal').setAttribute('style', 'display: block');
    document.getElementById('terminal-input').focus();
    terminal_active = true;
}

function hideTerminal() {
    document.getElementById('mini-about').innerHTML = "Welcome to Devadutta Ghat's Personal Website."
    document.getElementById('boot-loader').setAttribute('style', 'display: block');
    document.getElementById('terminal').setAttribute('style', 'display: none');
    terminal_active = false;
}

document.addEventListener('keydown', function(event) {

    if (event.key === 'Escape') {
        event.preventDefault();
        hideTerminal();
    }
    
    if(terminal_active) return;

    if (event.key === 'c') {
        event.preventDefault();
        showTerminal();
    }

    if (event.key === 'e') {
        event.preventDefault();
        document.getElementById('pk').text = 'public keys <-- Use these keys to send a pgp message to me.';
    }

});

// Handle tap events on mobile
document.querySelectorAll('.option').forEach(option => {
    option.addEventListener('click', function() {
        document.querySelector('.selected').classList.remove('selected');
        this.classList.add('selected');
        const link = this.querySelector('a');
        if (link) {
            window.open(link.href, '_blank');
        }
    });
});
